import {
    Chart,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    TimeSeriesScale,
    Legend,
    Tooltip,
    TooltipModel,
    TooltipItem
} from 'chart.js'
import dayjs from 'dayjs'
import _ from 'lodash'
import React, { useState, useEffect, useContext } from 'react'
import { Bar } from 'react-chartjs-2'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import 'dayjs/locale/pt-br'

import GenderDoughnutChart from '@/components/GenderDoughnutChart'
import HeatMap from '@/components/HeatMap'
import CampaignPlayer from '@/components/Player'
import { AuthContext } from '@/contexts/auth'
import { DateFilterContext } from '@/contexts/dateFilter'
import useDidMount from '@/hooks/useMount'
import ApiService from '@/services/Api'
import {
    WhatsApp as WhatsAppIcon
} from '@mui/icons-material'
import {
    Card,
    CardContent,
    CircularProgress,
    Grid,
    Typography,
    Skeleton,
    Divider,
    useMediaQuery
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import useStyles from './styles'

import 'chartjs-adapter-dayjs'

interface IAudience {
    timestamp: string;
    distributions: number;
    hour_start: string;
    hour_end: string;
}

type IListenersLocationResponse = {
    latitude: number
    longitude: number
    listeners: number
}[]

type IAudienceListenerResponse = {
    gender: 'female' | 'male'
    count: number
}

interface ITotalAds {
    totalViews: number
    totalClicks: number
}

const CampaignMetrics: React.FC = () => {
    dayjs.locale('pt-br')

    const { code, campaignUuid } = useParams()
    const [searchParams] = useSearchParams()
    const hasBetaAccess = !!searchParams.get('beta')

    const theme = useTheme()

    const { setDarkMode } = useContext(AuthContext)
    const { dateFilter } = useContext(DateFilterContext)

    const [campaign, setCampaign] = useState<any>({})
    const [totalDistributed, setTotalDistributed] = useState<any>({})
    const [totalBonusDistributed, setTotalBonusDistributed] = useState<any>({})
    const [totalListeners, setTotalListeners] = useState<any>({})
    const [totalDistributedDayhours, setTotalDistributedDayhours] =
        useState<any>([])
    const [ads, setAds] = useState<{ id: string; title: string; url: string }[]>([])

    const [loadingCampaign, setLoadingCampaign] = useState<any>({})
    const [loadingTotalDistributed, setLoadingTotalDistributed] =
        useState<boolean>(true)
    const [loadingTotalBonusDistributed, setLoadingTotalBonusDistributed] =
        useState<boolean>(true)
    const [loadingTotalListeners, setLoadingTotalListeners] =
        useState<boolean>(true)
    const [
        loadingTotalDistributedDayhours,
        setLoadingTotalDistributedDayhours
    ] = useState<boolean>(true)
    const [loadingAdvertisementAudio, setLoadingAdvertisementAudio] =
        useState<boolean>(true)

    const [chartData, setChartData] = useState<any>([])
    const [coordinates, setCoordinates] = useState<IListenersLocationResponse>()
    const [loadingChartData, setLoadingChartData] = useState<boolean>(true)
    const [listeners, setListeners] = useState<{ male: number, female: number }>()
    const [totalAds, setTotalAds] = useState<ITotalAds>({ totalClicks: 0, totalViews: 0 })
    const [loadingAudienceListeners, setLoadingAudienceListeners] = useState<boolean>(true)
    const [ageAverage, setAgeAverage] = useState<number>()
    const [loadingAudienceAgeAverage, setLoadingAudienceAgeAverage] = useState<boolean>(true)
    const [isLoadingAdsTotal, setIsLoadingAdsTotal] = useState<boolean>(true)

    const classes = useStyles()
    const navigate = useNavigate()
    const isSmallScreen = useMediaQuery('(max-width:430px)')

    const getCampaign = async () => {
        try {
            const { data } = await ApiService.get(
                `/metrics/campaigns/${code}`
            )

            setCampaign(data)
        } catch (error) {
            console.error('Error fetching campaign', error)
        } finally {
            setLoadingCampaign(false)
        }
    }

    const getCampaignCode = async () => {
        try {
            const { data } = await ApiService.get(
                `/metrics/campaigns/get-code/${campaignUuid}`
            )

            navigate(`/c/${data.code}`)
        } catch (error) {
            console.error('Error fetching campaign', error)
        }
    }

    const getTotalDistributed = async () => {
        try {
            const { data } = await ApiService.get(
                `/metrics/campaigns/${code}/distributed/total`
            )

            setTotalDistributed({
                quantity: data.quantity,
                quantityDistributed: data.quantityDistributed,
                percentage: data.percentage
            })
        } catch (error) {
            console.error('Error fetching total distributed', error)
        } finally {
            setLoadingTotalDistributed(false)
        }
    }

    const getTotalBonusDistributed = async () => {
        try {
            const { data } = await ApiService.get(
                `/metrics/campaigns/${code}/distributed/bonus/total`
            )

            setTotalBonusDistributed({
                quantity: data.quantity
            })
        } catch (error) {
            console.error('Error fetching total distributed', error)
        } finally {
            setLoadingTotalBonusDistributed(false)
        }
    }

    const getTotalListeners = async () => {
        try {
            const { data } = await ApiService.get(
                `/metrics/campaigns/${code}/listeners/total`
            )

            setTotalListeners({
                quantity: data.quantity
            })
        } catch (error) {
            console.error('Error fetching total listeners', error)
        } finally {
            setLoadingTotalListeners(false)
        }
    }

    const getAdvertisementAudio = async () => {
        try {
            const { data } = await ApiService.get(
                `/metrics/campaigns/${code}/advertisement/audio`
            )

            setAds(data.ads)
        } catch (error) {
            console.error('Error fetching advertisement audio', error)
        } finally {
            setLoadingAdvertisementAudio(false)
        }
    }

    const getAdvertisementTotal = async () => {
        try {
            const { data } = await ApiService.get(
                `/metrics/campaigns/${code}/advertisement/total`
            )
            setTotalAds(data)
        } catch (error) {
            console.error('Error fetching advertisement total', error)
        } finally {
            setIsLoadingAdsTotal(false)
        }
    }

    const getTotalDistributedDayhours = async () => {
        try {
            const { data }: any = await ApiService.get(
                `/metrics/campaigns/${code}/distributed/dayhours`
            )

            const labels = data?.map((row: any) => row.hour)

            const datasets = [
                {
                    tension: 0.2,
                    fill: false,
                    borderColor: '#73bf69',
                    backgroundColor: '#73bf6980',
                    borderWidth: 1,
                    yAxisID: 'y',
                    data: data?.map((row: any) => +row.quantity)
                }
            ]

            setTotalDistributedDayhours({
                labels,
                datasets
            })
        } catch (error) {
            console.error('Error fetching total distributed dayhours', error)
        } finally {
            setLoadingTotalDistributedDayhours(false)
        }
    }

    const getCampaignAudienceListeners = async () => {
        try {
            const { data } = await ApiService.get<IAudienceListenerResponse[]>(
                `/metrics/campaigns/${code}/audience/listeners`
            )

            const total = data.reduce((acc: number, item: IAudienceListenerResponse) => acc + Number(item.count), 0)
            const formattedListeners = data.reduce<{ male: number; female: number }>(
                (acc, item) => {
                    const percentage = Math.round((Number(item.count) / total) * 100)
                    acc[item.gender] = percentage
                    return acc
                },
                { male: 0, female: 0 }
            )

            setListeners(formattedListeners)
        } catch (error) {
            console.error('Error fetching campaign audience listeners', error)
        } finally {
            setLoadingAudienceListeners(false)
        }
    }

    const getCampaignAudienceAgeAverage = async () => {
        try {
            const { data } = await ApiService.get(
                `/metrics/campaigns/${code}/audience/average`
            )

            setAgeAverage(Math.ceil(data.avg))
        } catch (error) {
            console.error('Error fetching campaign audience age average', error)
        } finally {
            setLoadingAudienceAgeAverage(false)
        }
    }

    useDidMount(() => {
        if (campaignUuid) {
            return getCampaignCode()
        }
        getCampaign()
        getTotalDistributed()
        getTotalBonusDistributed()
        getTotalListeners()
        getTotalDistributedDayhours()
        setDarkMode(false)
        getAdvertisementAudio()
        getCampaignAudienceListeners()
        getCampaignAudienceAgeAverage()

        if (!hasBetaAccess) return
        getAdvertisementTotal()
    })

    Chart.register(
        CategoryScale,
        BarElement,
        PointElement,
        LineElement,
        TimeSeriesScale,
        Legend,
        Tooltip
    )

    const getDistribuitionsData = async () => {
        setLoadingChartData(true)

        const { data } = await ApiService.get(
            '/metrics/campaigns/' + code + '/distribuitions'
        )

        const labels = data?.map((audience: IAudience) => audience.timestamp)
        const hours = data?.map((audience: IAudience) => ({
            hourStart: audience.hour_start,
            hourEnd: audience.hour_end
        }))

        const datasets = [
            {
                tension: 0.2,
                fill: false,
                borderColor: '#73bf69',
                backgroundColor: '#73bf6980',
                borderWidth: 1,
                yAxisID: 'y',
                hourStart: hours.map((hour: { hourStart: any }) => hour.hourStart),
                hourEnd: hours.map((hour: { hourEnd: any }) => hour.hourEnd),
                data: data?.map((audience: IAudience) => +audience.distributions)
            }
        ]

        setChartData({
            labels,
            datasets
        })

        setLoadingChartData(false)
    }

    const getMapLocations = async () => {
        try {
            const { data } = await ApiService.get<IListenersLocationResponse>(
                `/metrics/campaigns/${code}/listeners/location`
            )

            setCoordinates(data)
        } catch (error) {
            return []
        }
    }

    useEffect(() => {
        getDistribuitionsData()
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            await getMapLocations()
        }

        const intervalId = setInterval(fetchData, 60 * 1000) // 1min

        fetchData()

        return () => clearInterval(intervalId)
    }, [code])

    return (
        <Grid
            container
            direction="column"
            wrap="nowrap"
            className={classes.mainContainer}
        >
            {loadingCampaign
                ? (
                    <Skeleton
                        variant="rectangular"
                        width={'100%'}
                        height={200}
                        sx={{ borderRadius: '10px', margin: '15px auto' }}
                    />
                )
                : campaign.logoUrl && (
                    <img src={campaign.logoUrl} alt={campaign.title} className={classes.logo} />
                )}

            <Card className={classes.cardContainer}>
                <CardContent className={classes.cardContent}>
                    <Grid container justifyContent="space-around">
                        {loadingCampaign
                            ? (
                                <CircularProgress size={80} color="primary" />
                            )
                            : (
                                <>
                                    <Grid item xs={12}>
                                        <Typography className={classes.titleText}>
                                            {campaign.title ?? 'Campanha'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            className={classes.descriptionText}
                                        >
                                            As métricas da sua campanha são
                                            atualizadas instantaneamente, permitindo
                                            que você acompanhe os resultados em
                                            tempo real.
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                    </Grid>
                </CardContent>
            </Card>

            {loadingAdvertisementAudio
                ? (
                    <Card className={classes.infoCardContainer}>
                        <CardContent className={classes.cardContent}>
                            <Grid container justifyContent="space-around">
                                <CircularProgress size={40} color="primary" />
                            </Grid>
                        </CardContent>
                    </Card>
                )
                : campaign.streamId && (
                    <Card className={classes.infoCardContainer}>
                        <CardContent className={classes.cardContent}>
                            <Grid container gap={2} justifyContent="start">
                                <Grid item xs={12}>
                                    <Typography className={classes.titleText}>
                                        <b
                                            style={{
                                                fontSize: '20px',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {ads.length > 1 ? 'Ouça suas propagandas' : 'Ouça sua propaganda'}
                                        </b>
                                    </Typography>
                                </Grid>
                                <Grid container gap={1} justifyContent="start">
                                    {ads.map((ad, i) => (
                                        <React.Fragment key={i}>
                                            <Typography variant="subtitle1">
                                                {ad.title}
                                            </Typography>
                                            <Grid sx={{ backgroundColor: '#cccccc' }} item xs={12} borderRadius={2}>
                                                <CampaignPlayer url={ad.url} id={ad.id} streamId={campaign.streamId} />
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )}

            {hasBetaAccess &&
                <>
                    {isLoadingAdsTotal
                        ? (
                            <Card className={classes.infoCardContainer}>
                                <CardContent className={classes.cardContent}>
                                    <Grid container justifyContent="space-around">
                                        <CircularProgress size={80} color="primary" />
                                    </Grid>
                                </CardContent>
                            </Card>
                        )
                        : (totalAds.totalViews > 1 || totalAds.totalClicks > 1) && (
                            <Card className={classes.infoCardContainer}>
                                <CardContent className={classes.cardContent}>
                                    <Grid container justifyContent="space-around">
                                        <Grid container justifyContent="space-around" alignItems="center" gap={2}>
                                            <Grid container justifyContent="start" alignItems="start" flexWrap="nowrap">
                                                <Typography className={classes.cardTitleText}>
                                                    Informações sobre o banner
                                                </Typography>
                                            </Grid>
                                            <Grid className={classes.flexContainer}>
                                                <div>
                                                    <Typography className={classes.cardDescriptionText}>
                                                        {isSmallScreen ? 'Visualizações: ' : 'Quantidade de visualizações:'}
                                                    </Typography>
                                                    <Grid container justifyContent="center">
                                                        <Typography className={classes.titleText}>
                                                            {totalAds.totalViews}
                                                        </Typography>
                                                    </Grid>
                                                </div>

                                                <Divider orientation="vertical" flexItem />
                                                <div>
                                                    <Typography className={classes.cardDescriptionText}>
                                                        {isSmallScreen ? 'Cliques: ' : 'Quantidade de cliques:'}
                                                    </Typography>
                                                    <Grid container justifyContent="center">
                                                        <Typography className={classes.titleText}>
                                                            {totalAds.totalClicks}
                                                        </Typography>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        )}
                </>
            }
            {loadingAudienceAgeAverage
                ? (
                    <Card className={classes.infoCardContainer}>
                        <CardContent className={classes.cardContent}>
                            <Grid container justifyContent="space-around">
                                <CircularProgress size={80} color="primary" />
                            </Grid>
                        </CardContent>
                    </Card>
                )
                : ageAverage && (
                    <Card className={classes.infoCardContainer}>
                        <CardContent className={classes.cardContent}>
                            <Grid container justifyContent="space-around">
                                <Grid container justifyContent="start">
                                    <Grid container justifyContent="space-between" alignItems="start" flexWrap="nowrap">
                                        <Typography className={classes.cardTitleText}>
                                Média de idade do público alcançado
                                        </Typography>
                                        <WhatsAppIcon className={classes.cardIcon} />
                                    </Grid>
                                    <Typography className={classes.cardDescriptionText}>
                            Estimativas geradas com Inteligência Artificial
                                    </Typography>
                                    <Grid container gap={1} justifyContent="start">
                                        <Typography className={classes.titleText}>
                                            {ageAverage ?? '0'} anos
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )}

            {loadingAudienceListeners
                ? (
                    <Card className={classes.infoCardContainer}>
                        <CardContent className={classes.cardContent}>
                            <Grid container justifyContent="space-around">
                                <CircularProgress size={80} color="primary" />
                            </Grid>
                        </CardContent>
                    </Card>
                )
                : listeners && (
                    <Card className={classes.infoCardContainer}>
                        <CardContent className={classes.cardContent}>
                            <Grid container justifyContent="space-around">
                                <Grid container justifyContent="start">
                                    <Grid container justifyContent="space-between" alignItems="start" flexWrap="nowrap">
                                        <Typography className={classes.cardTitleText}>
                                Percentual de público alcançado
                                        </Typography>
                                        <WhatsAppIcon className={classes.cardIcon} />
                                    </Grid>
                                    <Typography className={classes.cardDescriptionText}>
                            Estimativas geradas com Inteligência Artificial
                                    </Typography>
                                    <Grid container gap={1} justifyContent="start">
                                        <GenderDoughnutChart data={listeners} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )}
            <Card className={classes.infoCardContainer}>
                <CardContent className={classes.cardContent}>
                    <Grid container justifyContent="space-around">
                        {loadingTotalDistributed
                            ? (
                                <CircularProgress size={80} color="primary" />
                            )
                            : (
                                <>
                                    <Grid item xs={12}>
                                        <Typography className={classes.titleText}>
                                            <b
                                                style={{
                                                    fontSize: '30px',
                                                    fontWeight: 1000
                                                }}
                                            >
                                                {
                                                    totalDistributed.quantityDistributed > totalDistributed.quantity ? totalDistributed.quantity : totalDistributed.quantityDistributed
                                                }{' '}
                                                / {totalDistributed.quantity}
                                            </b>
                                            <b
                                                style={{
                                                    fontSize: '20px',
                                                    fontWeight: 100,
                                                    paddingLeft: '5px'
                                                }}
                                            >
                                                inserções
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div
                                            className={classes.progressContainer}
                                            style={{
                                                background: `linear-gradient(90deg, #73BF69 ${totalDistributed.percentage}%, #ccccdc30 ${totalDistributed.percentage}%`
                                            }}
                                        ></div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            className={classes.descriptionText}
                                        >
                                            Total de inserções distribuídas
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                    </Grid>
                </CardContent>
            </Card>

            {/* <Card className={classes.infoCardContainer}>
                <CardContent className={classes.cardContent}>
                    <Grid container justifyContent="space-around">
                        {loadingTotalListeners
                            ? (
                                <CircularProgress size={80} color="primary" />
                            )
                            : (
                                <>
                                    <Grid item xs={12}>
                                        <Typography className={classes.titleText}>
                                            <b
                                                style={{
                                                    fontSize: '30px',
                                                    fontWeight: 1000
                                                }}
                                            >
                                                {totalListeners.quantity}
                                            </b>
                                            <b
                                                style={{
                                                    fontSize: '20px',
                                                    fontWeight: 100,
                                                    paddingLeft: '5px'
                                                }}
                                            >
                                                ouvintes alcançados
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            className={classes.descriptionText}
                                        >
                                            Total de ouvintes alcançados
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                    </Grid>
                </CardContent>
            </Card> */}

            <Card className={classes.cardContainerDistribution}>
                <CardContent className={classes.cardContent}>
                    <Grid container className={classes.chartGrid}>
                        <Grid item xs={12} sm={12}>
                            <Grid container justifyContent="space-around">
                                <Typography
                                    className={classes.titleTextDistribution}
                                >
                                    Comerciais veiculados
                                </Typography>
                            </Grid>
                        </Grid>
                        {loadingChartData
                            ? (
                                <Grid item xs={12} sm={12}>
                                    {_.times(28, (i: any) => (
                                        <Skeleton
                                            variant="rectangular"
                                            width="2.5%"
                                            height={(i + 1) * 11}
                                            style={{
                                                float: 'left',
                                                marginLeft: '1%',
                                                marginTop: 360 - (i + 1) * 11
                                            }} />
                                    ))}
                                </Grid>
                            )
                            : (
                                <Grid item xs={12} sm={12}>
                                    {chartData && chartData?.datasets && (
                                        <Bar
                                            height={80}
                                            data={chartData}
                                            options={{
                                                plugins: {
                                                    legend: {
                                                        display: false
                                                    },
                                                    tooltip: {
                                                        backgroundColor: '#202227',
                                                        titleColor: '#ccccdc',
                                                        boxHeight: 1,
                                                        boxPadding: 3,
                                                        bodySpacing: 5,
                                                        callbacks: {
                                                            label: function (
                                                                this: TooltipModel<'bar'>,
                                                                tooltipItem: TooltipItem<'bar'>
                                                            ) {
                                                                return (
                                                                    tooltipItem.formattedValue +
                                                                    ' veiculações'
                                                                )
                                                            },
                                                            title: function (
                                                                this: TooltipModel<'bar'>,
                                                                tooltipItem: TooltipItem<'bar'>[]
                                                            ) {
                                                                const dateString = tooltipItem[0].label
                                                                const date = dayjs(dateString, 'MMM D, YYYY, h:mm:ss A')
                                                                const formattedDate = date.format('DD/MM/YYYY')
                                                                const start = (this.chart.data.datasets[0] as any).hourStart[0]
                                                                const [startHour, startMinute] = start.split(':').slice(0, 2)
                                                                const end = (this.chart.data.datasets[0] as any).hourEnd[0]
                                                                const [endHour, endMinute] = end.split(':').slice(0, 2)
                                                                return `${formattedDate} | ${startHour}:${startMinute} - ${endHour}:${endMinute}`
                                                            }
                                                        }
                                                    }
                                                },
                                                responsive: true,
                                                interaction: {
                                                    mode: 'index',
                                                    intersect: false
                                                },
                                                elements: {
                                                    point: {
                                                        radius: 0
                                                    }
                                                },
                                                scales: {
                                                    x: {
                                                        type: 'timeseries', // Define o tipo do eixo como 'time', que indica que os valores são datas
                                                        time: {
                                                            unit: 'day',
                                                            displayFormats: {
                                                                day: 'DD/MM' // Define o formato de exibição para as datas no eixo x
                                                            }
                                                        },
                                                        grid: {
                                                            color: theme.palette.info[theme.palette.mode],
                                                            borderColor: theme.palette.info[theme.palette.mode]
                                                        },
                                                        ticks: {
                                                            color: theme.palette.primary[theme.palette.mode],
                                                            source: 'data',
                                                            callback: function (value) {
                                                                const date = dayjs(value, 'DD/MM')
                                                                const dayOfWeek = date.format('ddd')
                                                                const capitalizedDay = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)
                                                                return `${value} (${capitalizedDay})`
                                                            }
                                                        }
                                                    },
                                                    y: {
                                                        beginAtZero: true,
                                                        type: 'linear',
                                                        display: true,
                                                        position: 'left',
                                                        grid: {
                                                            color: theme.palette.info[theme.palette.mode],
                                                            borderColor: theme.palette.info[theme.palette.mode]
                                                        },
                                                        ticks: {
                                                            color: theme.palette.primary[theme.palette.mode]
                                                        }
                                                    }
                                                }

                                            }}
                                        />
                                    )}
                                </Grid>
                            )}
                    </Grid>
                </CardContent>
            </Card>

            <Card className={classes.cardContainerDistribution} style={{ marginBottom: 15 }}>
                <CardContent className={classes.cardContent}>
                    <Grid container className={classes.chartGrid}>
                        <Grid item xs={12} sm={12}>
                            <Grid container justifyContent="space-around">
                                <Typography
                                    className={classes.titleTextDistribution}
                                >
                                    Ouvintes por cidade
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            className={classes.mapsContainer}
                        >
                            <HeatMap coordinates={coordinates} address={campaign.address} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {/* <Card className={classes.infoCardContainer}>
        <CardContent className={classes.cardContent}>
            <Grid container justifyContent="space-around">
                {
                    loadingTotalBonusDistributed ?
                        <CircularProgress size={80} color="primary" /> :
                        <>
                            <Grid item xs={12}>
                                <Typography className={classes.titleText}>
                                    <b style={{ fontSize: "30px", fontWeight: 1000 }}>{totalBonusDistributed.quantity}</b>
                                    <b style={{ fontSize: "20px", fontWeight: 100, paddingLeft: '5px' }}>veiculações bônus</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.descriptionText}>
                                    Essas são veiculações que nós distribuimos de forma gratuita para atingir um numero maior de ouvintes.
                                </Typography>
                            </Grid>
                        </>
                }
            </Grid>
        </CardContent>
    </Card> */}

            {/* <Card className={classes.infoCardContainer} style={{ marginBottom: "60px" }}>
        <CardContent className={classes.cardContent}>
            <Grid item xs={12}>
                {
                    loadingTotalDistributedDayhours ?
                        <CircularProgress size={80} color="primary" /> :
                        <>
                            {
                                totalDistributedDayhours &&
                                totalDistributedDayhours?.datasets &&
                                <>
                                    <Grid item xs={12}>
                                        <Typography className={classes.titleText}>
                                            <b style={{ fontSize: "30px", fontWeight: 1000 }}>HORÁRIOS VEICULADOS</b>
                                        </Typography>
                                    </Grid>
                                    <Bar
                                        height={120}
                                        data={totalDistributedDayhours}
                                        options={{
                                            plugins: {
                                                legend: {
                                                    display: false
                                                },
                                                tooltip: {
                                                    backgroundColor: '#202227',
                                                    titleColor: '#ccccdc',
                                                    boxHeight: 1,
                                                    boxPadding: 3,
                                                    bodySpacing: 5,
                                                    callbacks: {
                                                        label: function (this: TooltipModel<'bar'>, tooltipItem: TooltipItem<'bar'>) {
                                                            // console.log(tooltipItem)
                                                            return tooltipItem.formattedValue + ' ouvintes'
                                                        }
                                                    }
                                                }
                                            },
                                            responsive: true,
                                            interaction: {
                                                mode: 'index',
                                                intersect: false
                                            },
                                            elements: {
                                                point: {
                                                    radius: 0
                                                }
                                            },
                                            scales: {
                                                x: {
                                                    type: 'category',
                                                    grid: {
                                                        color: '#272a2e',
                                                        borderColor: '#272a2e'
                                                    },
                                                    ticks: {
                                                        color: '#ccccdc'
                                                    }
                                                },
                                                y: {
                                                    type: 'linear',
                                                    display: true,
                                                    position: 'left',
                                                    grid: {
                                                        color: '#272a2e',
                                                        borderColor: '#272a2e'
                                                    },
                                                    ticks: {
                                                        color: '#ccccdc'
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </>
                            }
                        </>
                }
            </Grid>
        </CardContent>
    </Card> */}
        </Grid >
    )
}

export default CampaignMetrics
