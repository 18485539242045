import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    tableContainer: {
        paddingBottom: theme.spacing(10)
    },
    gridTable: {
        margin: theme.spacing(2),
        background: theme.palette.background.default
    },
    inputs: {
        maxWidth: '40%'
    },
    dialogTitle: {
        background: theme.palette.background.default,
        color: theme.palette.primary[theme.palette.mode],
        textAlign: 'center'
    },
    dialogContainer: {
        background: theme.palette.background.default,
        color: theme.palette.primary[theme.palette.mode]
    },
    dialogActions: {
        background: theme.palette.background.default,
        color: theme.palette.primary[theme.palette.mode]
    },
    dialogItemInput: {
        padding: theme.spacing(1)
    },
    selectItemInput: {
        padding: theme.spacing(1)
    },
    setGridButtonValid: {
        margin: theme.spacing(2)
    },
    setGridButtonNotValid: {
        margin: theme.spacing(2),
        color: '#A0A0A0 !important'
    },
    itemInput: {
        width: '100%'
    },
    formControl: {
        width: '100%'
    },
    itemSelect: {
        height: theme.spacing(5)
    },
    selectInput: {
        marginTop: '-7px'
    },
    weekdayButton: {
        height: '60px',
        borderRadius: '50%',
        margin: theme.spacing(1)
    },
    stackWeek: {
        width: '100%',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    dateHourGrid: {
        paddingTop: theme.spacing(2)
    },
    deleteProgramDialogCancelButton: {
        color: theme.palette.primary[theme.palette.mode]
    },
    panel: {
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        borderRadius: '5px',
        opacity: '0.9'

    },
    panelText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: '10px',
        color: theme.palette.primary.contrastText,
        flexDirection: 'column'
    }
}))

export default useStyles
