import dayjs from 'dayjs'
import { set } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useContext, useState } from 'react'

import Divider from '@/components/Divider'
import { AuthContext } from '@/contexts/auth'
import { WeekdayTypeEnum } from '@/enum/WeekdayTypeEnum'
import { getWeekdayListLiteral } from '@/helpers/getWeekdayListLiteral'
import { useFetch } from '@/hooks/useFetch'
import ApiService from '@/services/Api'
import {
    DeleteOutline as DeleteIcon,
    EditOutlined as EditIcon
} from '@mui/icons-material'
import {
    Grid,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogActions,
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    tableCellClasses,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Stack,
    IconButton,
    Typography,
    useTheme
} from '@mui/material'

import useStyles from './styles'

type IGrid = {
    uuid?: string
    weekdays: Array<WeekdayTypeEnum>
    startAt: string | null
    endAt: string | null
}

export type IProgram = {
    uuid: string
    companyUuid: string
    name: string
    segment: Array<string>
    grid: Array<IGrid>,
    createdAt: Date
    updatedAt: Date
}

const Program: React.FC = () => {
    const { darkMode, user } = useContext(AuthContext)
    const link = `https://metrics-v2.loopert.com/login?token=${user?.metricsv2Token}`

    const theme = useTheme()

    const tableCellStyle = {
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: darkMode ? '#181924' : '#fafafa',
            color: theme.palette.success[theme.palette.mode]
        },
        [`&.${tableCellClasses.body}`]: {
            color: theme.palette.success[theme.palette.mode]
        }
    }

    const tableRowStyle = {
        '&:nth-of-type(odd)': {
            backgroundColor: darkMode ? '#181924' : '#fafafa',
            color: theme.palette.success[theme.palette.mode]
        },
        '&:nth-of-type(even)': {
            backgroundColor: darkMode ? '#181924' : '#fafafa',
            color: theme.palette.success[theme.palette.mode]
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0
        }
    }

    const textFieldStyle = {
        '& ::-webkit-calendar-picker-indicator': {
            filter: 'invert(1)'
        },
        '& .MuiInputLabel-root': {
            color: theme.palette.success[theme.palette.mode]
        },
        '& .MuiOutlinedInput-root': {
            color: `${theme.palette.success[theme.palette.mode]} '!important'`,
            '& fieldset': {
                borderColor: `${theme.palette.success[theme.palette.mode]} '!important'`
            }
        }
    }

    const formControlStyle = {
        '& .MuiInputLabel-root': {
            color: `${theme.palette.success[theme.palette.mode]} '!important'`
        },
        '& .MuiOutlinedInput-root': {
            color: `${theme.palette.success[theme.palette.mode]} '!important'`,
            '& fieldset': {
                borderColor: `${theme.palette.success[theme.palette.mode]} '!important'`
            }
        },
        '& .MuiSvgIcon-root': {
            fill: `${theme.palette.success[theme.palette.mode]} '!important'`
        }
    }

    document.title = 'Programas | Loopert'
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()

    const [programs, setPrograms] = useState<IProgram[]>([])
    const [program, setProgram] = useState<IProgram>({} as IProgram)
    const [grid, setGrid] = useState<IGrid>({ startAt: null, endAt: null } as IGrid)
    const [deleteUuid, setDeleteUuid] = useState<string>('')
    const [openedProgramDialog, setOpenedProgramDialog] = useState<boolean>(false)
    const [openedDeleteConfirmationDialog, setOpenedDeleteConfirmationDialog] = useState<boolean>(false)
    const [loadingProgram, setLoadingProgram] = useState<boolean>(false)
    const [isValidTimeRang, setIsValidTimeRang] = useState<boolean>(false)

    const [searchInput, setSearchInput] = useState<string>('')

    const openProgramDialog = (program?: IProgram) => {
        setProgram(
            program || ({ segment: [] } as unknown as IProgram)
        )
        setGrid({ startAt: null, endAt: null, weekdays: [dayjs().day()] } as IGrid)

        setOpenedProgramDialog(true)
    }

    const validateTimeRange = (startAt: string, endAt: string) => {
        // Função para converter horário para UTC
        const toUTCTimeString = (time: string) => {
            const [hours, minutes] = time.split(':')
            const date = new Date()

            // Define o horário em BR (horário local)
            date.setHours(Number(hours), Number(minutes), 0, 0)

            // Converte o horário para UTC
            const utcHours = date.getUTCHours().toString().padStart(2, '0')
            const utcMinutes = date.getUTCMinutes().toString().padStart(2, '0')

            // Retorna o horário em UTC no formato "HH:mm"
            return `${utcHours}:${utcMinutes}`
        }

        const start = parseFloat(startAt.split(':').join('.'))

        const endInUTC = toUTCTimeString(endAt)
        const end = parseFloat(endInUTC.split(':').join('.'))

        const timeRange = start > end ? (end + 24) - start : end - start
        console.log('horas comparadas::::', start, end)

        return timeRange <= 10
    }

    const validateClientGrid = async (startAt: string, endAt: string, weekdays: Array<number>, uuid?: string) => {
        const start = parseFloat(startAt.split(':').join('.'))
        const end = parseFloat(endAt.split(':').join('.'))

        // Encontrar o grid que já existe com base nos horários e dias da semana
        const existingGrid = program?.grid?.find(_grid => {
            const gridStart = parseFloat(_grid?.startAt!.split(':').join('.'))
            const gridEnd = parseFloat(_grid?.endAt!.split(':').join('.'))
            const someWeekdays = _grid.weekdays.some(weekday => weekdays.includes(weekday))
            const someLastWeekdays = _grid.weekdays.some(weekday => weekdays.includes(weekday === 0 ? 6 : weekday - 1))
            const someNextWeekdays = _grid.weekdays.some(weekday => weekdays.includes(weekday === 6 ? 0 : weekday + 1))

            return Boolean(
                (
                    someNextWeekdays &&
                    start > end &&
                    (
                        gridStart < end ||
                        gridEnd < end
                    )
                ) ||
                (
                    someWeekdays &&
                    (
                        (
                            (
                                gridStart <= start &&
                                gridEnd >= end
                            ) &&
                            (start < end)
                        ) ||
                        (
                            gridStart < gridEnd &&
                            start < end &&
                            (
                                (
                                    gridStart > start &&
                                    gridEnd < end
                                ) ||
                                (
                                    gridStart <= start &&
                                    gridEnd > start
                                ) ||
                                (
                                    gridStart < end &&
                                    gridEnd >= end
                                )
                            )
                        ) ||
                        (
                            gridStart > gridEnd &&
                            (
                                gridStart < start ||
                                gridStart < end ||
                                (gridEnd > end && start > end) ||
                                (gridStart >= start && start > end)
                            )
                        )
                    )
                ) ||
                (
                    someLastWeekdays &&
                    gridStart > gridEnd &&
                    start < end &&
                    (
                        gridEnd > start ||
                        gridEnd > end
                    )
                )
            )
        })

        // Se encontrar um grid existente, procure o nome do programa associado ao grid
        if (existingGrid) {
            // Encontrar o nome do programa baseado no grid
            const programWithGrid = program
            const programName = programWithGrid ? programWithGrid.name : 'Desconhecido'

            return { available: false, programName }
        }

        // Caso contrário, retorna 'true' indicando que o horário está disponível
        return { available: true, programName: '' }
    }

    const formatTime = (time: string | null | undefined): string => {
        if (!time) {
            return '' // Retorna vazio se o valor for nulo ou indefinido
        }

        const formattedTime = new Date(`1970-01-01T${time}Z`).toLocaleTimeString('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        })

        return formattedTime
    }

    const validateGrid = async (_grid: IGrid) => {
        const startAt = _grid.startAt
        const endAt = _grid.endAt

        if (!startAt || !endAt) {
            return setIsValidTimeRang(false)
        }

        if (startAt === endAt) {
            enqueueSnackbar(('O horário de inicio e término não podem ser iguais.') as String, {
                variant: 'error'
            })

            return setIsValidTimeRang(false)
        }

        if (!startAt || !endAt) {
            enqueueSnackbar(('É necessário selecionar os horários de inicio e fim.') as String, {
                variant: 'error'
            })

            return setIsValidTimeRang(false)
        }

        if (_grid.weekdays.length <= 0) {
            enqueueSnackbar(('É necessário selecionar pelo menos um dia da semana.') as String, {
                variant: 'error'
            })

            return setIsValidTimeRang(false)
        }

        const validTimeRange: boolean = validateTimeRange(startAt, endAt)

        if (!validTimeRange) {
            enqueueSnackbar(('A diferença de horário não pode ser maior que 10 horas.') as String, {
                variant: 'error'
            })

            return setIsValidTimeRang(false)
        }

        const { available, programName } = await validateClientGrid(startAt, endAt, _grid.weekdays, _grid.uuid)

        if (!available) {
            console.log(`O horário já está ocupado pelo programa: ${programName}`)
            return setIsValidTimeRang(false)
        }

        await ApiService.get<IProgram[]>(`/metrics/program/is-available?startAt=${startAt}&endAt=${endAt}&weekdays=${_grid.weekdays.join('_')}${_grid.uuid ? `&uuid=${_grid.uuid}` : ''}`)
            .then(() => {
                setIsValidTimeRang(true)
            })
            .catch((error) => {
                console.log('Errororororo', error)
                const errorMessage = error.data || 'teste.'
                enqueueSnackbar(errorMessage, { variant: 'error' })
                setIsValidTimeRang(false)
            })
    }

    const handleCloseProgramDialog = () => {
        setOpenedProgramDialog(false)
    }

    const handleValidateGrid = async ({
        startAt,
        endAt
    }: {
        startAt?: string,
        endAt?: string,
        weekday?: string
    }) => {
        let _grid: IGrid = grid

        if (startAt) _grid = { ...grid, startAt }
        if (endAt) _grid = { ...grid, endAt }

        await validateGrid(_grid)
    }

    const handleSetGrid = async ({
        startAt,
        endAt
    }: {
        startAt?: string,
        endAt?: string,
        weekday?: string
    }) => {
        let _grid: IGrid = grid

        const toUTCTimeString = (time: string) => {
            const [hours, minutes] = time.split(':')
            const date = new Date()

            // Define o horário em BR (horário local)
            date.setHours(Number(hours), Number(minutes), 0, 0)

            // Converte o horário para UTC
            const utcHours = date.getUTCHours().toString().padStart(2, '0')
            const utcMinutes = date.getUTCMinutes().toString().padStart(2, '0')

            // Retorna o horário em UTC, formato "HH:mm"
            return `${utcHours}:${utcMinutes}`
        }

        if (startAt) _grid = { ...grid, startAt: toUTCTimeString(startAt) }
        if (endAt) _grid = { ...grid, endAt: toUTCTimeString(endAt) }

        setGrid(_grid)
    }

    const getPrograms = async () => {
        try {
            const { data } = await ApiService.get<IProgram[]>('/metrics/program')

            setPrograms(data)
        } catch (e) {
            console.log('Erro ao buscar os programas:', e)
        }
    }

    const saveProgram = async () => {
        try {
            setLoadingProgram(true)

            await ApiService.post('/metrics/program', program)

            handleCloseProgramDialog()
        } catch (data) {
            enqueueSnackbar((data || 'Ocorreu um erro ao criar o programa.') as String, {
                variant: 'error'
            })
        }

        getPrograms()

        setLoadingProgram(false)
    }

    const handleWeekdayList = async ({ weekday }: { weekday: WeekdayTypeEnum }) => {
        const { weekdays } = grid

        let _grid: IGrid

        if (!weekdays) {
            _grid = { ...grid, weekdays: [weekday] }
        } else if (weekdays.includes(weekday)) {
            weekdays.splice(weekdays.indexOf(weekday), 1)

            _grid = { ...grid, weekdays }
        } else {
            _grid = { ...grid, weekdays: [...weekdays, weekday] }
        }

        setGrid(_grid)

        await validateGrid(_grid)
    }

    const handleSetProgramGrid = async () => {
        const gridList = program?.grid

        if (grid.uuid) {
            handleRemoveGrid(program.grid.indexOf(grid))
        }

        if (!gridList) {
            setProgram({
                ...program,
                grid: [{
                    ...(grid.uuid ? { uuid: grid.uuid } : {}),
                    ...{
                        weekdays: grid.weekdays,
                        startAt: grid.startAt,
                        endAt: grid.endAt
                    }
                }]
            })
        } else {
            setProgram({
                ...program,
                grid: [
                    ...gridList,
                    {
                        weekdays: grid.weekdays,
                        startAt: grid.startAt,
                        endAt: grid.endAt
                    }
                ]
            })
        }

        setGrid({ startAt: null, endAt: null, weekdays: [dayjs().day()] } as IGrid)
        setIsValidTimeRang(false)
    }

    const handleRemoveGrid = (index: number) => {
        const programGrid = program.grid

        programGrid.splice(index)

        setProgram({ ...program, grid: programGrid })
    }

    const handleProgramsFilled = (): boolean => {
        return Boolean(program?.name &&
            program?.segment?.length > 0 &&
            program?.grid?.length > 0
        )
    }

    const deleteProgram = async () => {
        setOpenedDeleteConfirmationDialog(false)
        try {
            await ApiService.delete<IProgram>(`/metrics/program/${deleteUuid}`)

            enqueueSnackbar('Programa removido com sucesso.', {
                variant: 'success'
            })
            getPrograms()
        } catch (e) {
            enqueueSnackbar('Não foi possivel apagar o programa.', {
                variant: 'error'
            })
        }
    }

    useFetch<IProgram[]>(
        '/metrics/program',
        (data: IProgram[]) => {
            setPrograms(data)
        }
    )

    return <>
        <div className={classes.panel}>
            <div className={classes.panelText}>
                <label style={{ fontFamily: 'Roboto, sans-serif', fontWeight: '500', marginTop: '30px', fontSize: '18px' }}>Ops! A tela de Programas não está mais disponível na versão atual do Metrics.</label>
                <span style={{ fontFamily: 'Roboto, sans-serif', fontWeight: '500', marginTop: '10px', fontSize: '18px', marginBottom: '30px' }}>
                    <a href={link} style={{ color: '#fff', textDecoration: 'underline' }}>
                        Clique aqui
                    </a>
                    {' para acessar a nova interface no Metrics v2!'}
                </span>

            </div>
        </div>

    </>
}

export default Program
