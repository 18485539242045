import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    mainContainer: {
        width: '500px',
        maxWidth: '90%',
        margin: '0 auto',
        height: 'fit-content'
    },
    cardContainer: {
        padding: 0,
        background: theme.palette.secondary[theme.palette.mode], // theme.palette.secondary.light
        position: 'relative',
        marginTop: '15px'
    },
    cardContainerDistribution: {
        padding: 0,
        background: theme.palette.secondary[theme.palette.mode],
        marginTop: '15px'
    },
    logo: {
        margin: '15px auto',
        borderRadius: '10px',
        height: '200px'
    },
    titleTextDistribution: {
        fontSize: 20,
        fontWeight: 600,
        color: theme.palette.primary[theme.palette.mode],
        marginBottom: 10
    },
    cardContent: {
        background: theme.palette.secondary[theme.palette.mode],
        paddingBottom: `${theme.spacing(2)} !important`,
        paddingLeft: theme.spacing(2)
    },
    titleText: {
        fontSize: '27px',
        color: theme.palette.primary[theme.palette.mode],
        paddingLeft: theme.spacing(0.2),
        fontWeight: '900'
    },
    descriptionText: {
        marginTop: '10px',
        fontSize: '15px',
        color: theme.palette.primary[theme.palette.mode],
        paddingLeft: theme.spacing(0.2),
        fontWeight: '900'
    },
    cardTitleText: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: theme.palette.primary[theme.palette.mode],
        paddingLeft: theme.spacing(0.2)
    },
    cardDescriptionText: {
        fontSize: '13px',
        color: 'gray',
        paddingLeft: theme.spacing(0.2),
        fontWeight: '500'
    },
    cardIcon: {
        marginTop: theme.spacing(0.5)
    },
    infoCardContainer: {
        background: '#181b1f',
        position: 'relative',
        marginTop: '15px'
    },
    progressContainer: {
        marginTop: '10px',
        marginBottom: '10px',
        width: '100%',
        height: '25px',
        borderRadius: '100px'
    },
    chartGrid: {
        flexGrow: 1
    },
    tooltipContainer: {
        padding: theme.spacing(2),
        background: theme.palette.secondary.main,
        borderRadius: theme.spacing(1),
        color: theme.palette.secondary.contrastText,
        boxShadow: '2px 2px gray'
    },
    tooltipValueText: {
        fontSize: '19px',
        fontWeight: 700
    },
    tooltipDatetimeText: {
        fontSize: '13px'
    },
    headerContainer: {
        paddingBottom: theme.spacing(1)
    },
    selectIntervalInMinutes: {
        color: '#ccccdc', // theme.palette.secondary.contrastText,
        height: '40px',
        width: '150px'
    },
    averagePositionText: {
        fontSize: 18,
        fontWeight: 600,
        color: '#ccccdc' // theme.palette.secondary.contrastText
    },
    betaBadge: {
        // float: 'left',
        marginLeft: theme.spacing(2)
    },
    mapsContainer: {
        height: '400px',
        width: '100%'
    },
    flexContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-around',
        gap: theme.spacing(1)
    }
}))

export default useStyles
